import React, { useEffect, useState, useContext } from "react"
import { Row, Col, Modal, Input, Spinner, Table } from "reactstrap"
import "../../assets/scss/manual/manual.scss";
import toast from 'react-hot-toast';
import axios from "axios";
import AppConfig from "constants/config";
import { AuthContext } from "context/authContext";

const AddUserToClient = (prop) => {
    const { userData } = useContext(AuthContext)
    const { addOpen, toggleAdd, setOpenPopup, user, getUserDetails } = prop
    const [clients, setClient] = useState([])
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [loading, setLoading] = useState(false)
    const [search, setSearch] = useState('')
    const [filterClients, setFilterClients] = useState([])

    useEffect(() => {
        const getAvailableClients = async() => {
            setLoading(true)
            try {
                const {data} = await axios.get(`${AppConfig.baseUrl}/users/available_clients?user_id=${user._id}&customer_id=${userData.id}`,{
                  headers: { token: localStorage.getItem("token") }
                });
                if(!data.error){
                    const newArr = data && data.availableClients ? [...data.availableClients].map(v => {
                        v.access_type = 'read_only'
                        return v;
                    }) : []
                    setClient(newArr)
                    setFilterClients(newArr)
                }
                else{
                  throw new Error(data.title);
                }
            } catch (error) {
                toast.error(error.message || 'Something went wrong');
            }
            setLoading(false)
        }
        if(user && user._id && userData && userData.id){
            getAvailableClients();
        }
    },[user])

    const checkAll = (type) => {
        const data = clients.filter(v => type == "readOnly" ? v.access_type == 'read_only' : type == "fullAccess" ? v.access_type == 'full_access' : v.is_processed)
        return clients.length > 0 && data.length == clients.length
    }
    
    const onSubmit = async () => {
        setSearch('');
        setIsSubmitting(true)
        try {
            let clientsData = clients.filter(v => v.is_processed)
            let values = {user_id : user._id, clients : clientsData, customer_id: userData.id }
            const { data } = await axios.post( `${AppConfig.baseUrl}/user_client/add_user_client`, values, {
                headers: { token: localStorage.getItem("token")},
            });
            if (!data.error) {
                toast.success("Client added to user successfully");
                getUserDetails(user._id)
            } else {
                throw new Error(data.title)
            }
            setOpenPopup(false);
        } catch (error) {
            toast.error(error.message || "Something went wrong")
        }
        setIsSubmitting(false)
    }

    const handleClients = (value) =>{
        if(value){
            const data = [...filterClients].filter(v => v.client.match(new RegExp(value,"i")))
            setClient(data)
        }else{
            setClient(filterClients)
        }
    }

    const handleAccessType = (value) =>{
        const newArr = [...clients].map(v => {
            v.access_type = value
            v.can_review = false
            return v;
        })
        setClient(newArr)
    }

    return (
        <Modal
            isOpen={addOpen}
            toggle={() => {
                toggleAdd();
                setSearch('');
                setClient(filterClients)
            }}
            size="lg"
            centered
        >
            <div className="modal-header">
                <h5 className="modal-title mt-0">{`Add a client to ${user.name}`}</h5>
                <button
                    type="button"
                    onClick={() => {
                        setOpenPopup(false);
                        setSearch('');
                        setClient(filterClients)
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                {
                    loading ?
                    <div className="text-center"><Spinner color="primary" className="text-lbusers" /></div>
                    :   
                    <div className="modal-body">
                        <Row>
                            <Col md="4">
                                <Input
                                placeholder="Search"
                                    className="mb-2"
                                    name="search"
                                    type="text"
                                    onChange={(e) => {setSearch(e.target.value); handleClients(e.target.value)}}
                                    value={search}
                                />
                            </Col>
                        </Row>
                        <div className="table-responsive">
                            <Table className="table mb-0 text-center customTable">
                                <thead className="table-light tableHead">
                                    <tr style={{paddingRight:'15px'}}>
                                        <th style={{width:'100px'}}>
                                            <div className="mt-4">
                                                <Input
                                                    className="form-check-input"
                                                    name="selectAll"
                                                    type="checkbox"
                                                    checked={checkAll()}
                                                    onClick={(e) => {
                                                        if (e.target.checked) {
                                                            const newArr = clients.map(v => {
                                                                v.is_processed = true
                                                                return v;
                                                            })
                                                            setClient(newArr)
                                                        } else {
                                                            const newArr = clients.map(v => {
                                                                v.is_processed = false
                                                                return v;
                                                            })
                                                            setClient(newArr)
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </th>
                                        <th>Client</th>
                                        <th>
                                            <div className="d-flex justify-content-center">
                                                <div className="mt-4">
                                                    <Input
                                                        className="form-check-input"
                                                        name="selectReadOnly"
                                                        type="checkbox"
                                                        checked={checkAll('readOnly')}
                                                        onClick={(e) => {
                                                            if(e.target.checked){
                                                                handleAccessType('read_only') 
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <div className="ms-1 mt-4">Read Only</div>
                                            </div>
                                        </th>
                                        <th>
                                            <div className="d-flex justify-content-center">
                                                <div className="mt-4">
                                                    <Input
                                                        className="form-check-input"
                                                        name="selectFullAccess"
                                                        type="checkbox"
                                                        checked={checkAll('fullAccess')}
                                                        onClick={(e) => {
                                                            if(e.target.checked){
                                                                handleAccessType('full_access')   
                                                            } 
                                                        }}
                                                    />
                                                </div>
                                                <div className="ms-1 mt-4">Full Access</div>
                                            </div>
                                        </th>
                                        <th style={{width:'150px'}}>Lease Review</th>
                                    </tr>
                                </thead>
                                <tbody className="tableBody">
                                {   clients && clients.length < 1 ?
                                <>
                                    <tr><td colSpan={5}>No data found</td></tr>
                                </>
                                :
                                    (clients && clients.length > 0) && clients.map((data, i) => {
                                        return <tr key={data.type + i} style={{ textDecoration: data.deletable ? 'line-through' : 'none', textDecorationColor: data.deletable ? 'red' : '' }} >
                                            <td className="text-center" style={{width:'100px'}}>
                                                <div className="mt-2">
                                                    <Input
                                                        className="form-check-input"
                                                        name="includePayment"
                                                        id={i}
                                                        type="checkbox"
                                                        disabled={data.deletable}
                                                        checked={data.is_processed}
                                                        onClick={() => {
                                                            let newArr = [...clients];
                                                            newArr[i].is_processed = newArr[i].is_processed ? false : true
                                                            setClient(newArr)
                                                        }}
                                                    />
                                                </div>
                                            </td>
                                            <td><div className="mt-2">{data.client ? data.client : ''}</div></td>
                                            <td>
                                                <div className="mt-2">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    checked={data.access_type == 'read_only' || false}
                                                    onChange={(e) => {
                                                            let newArr = [...clients];
                                                            newArr[i].access_type = 'read_only'
                                                            newArr[i].can_review = false
                                                            setClient(newArr)
                                                    }}
                                                />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="mt-2">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    checked={data.access_type == 'full_access' || false}
                                                    onChange={(e) => {
                                                            let newArr = [...clients];
                                                            newArr[i].access_type = 'full_access'
                                                            newArr[i].can_review = false
                                                            setClient(newArr)
                                                    }}
                                                />
                                                </div>
                                            </td>
                                            <td style={{width:'150px'}}>
                                                <div className="d-flex mb-0 justify-content-center">
                                                    <div className="form-check form-switch form-switch-md mt-2 ms-3">
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input switch-icon-color"
                                                            id="can_review"
                                                            name="can_review"
                                                            disabled={data.access_type !== "full_access"}
                                                            checked = {data.can_review || false}
                                                            onChange={(e) => {
                                                                let newArr = [...clients];
                                                                newArr[i].can_review = e.target.checked
                                                                setClient(newArr)
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    })                                    
                                }
                                </tbody>
                            </Table>
                        </div>
                    </div>
                }
                <div className="modal-footer mt-3">
                    <button type="button" className="btn btn-lbusers" disabled={isSubmitting} onClick={onSubmit}>
                        {
                            isSubmitting && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> 
                        }
                        SUBMIT
                    </button>
                </div>
            </div>
        </Modal>
    )
}

export default AddUserToClient
import React,{useEffect, useState, useContext} from "react"
import {Container,Label,Spinner,Row,Col, CardBody,Card, CardTitle,CardHeader,CardText} from "reactstrap"
import "../../assets/scss/manual/manual.scss";
import toast from 'react-hot-toast';
import axios from "axios";
import AppConfig from "constants/config";
import { AuthContext } from "context/authContext";
import {Helmet} from "react-helmet";
import Breadcrumb from "../../components/Common/Breadcrumb";
import { useLocation, useNavigate } from "react-router-dom";
import Layout from "components/VerticalLayout";

const Modules = () => {
    const { userData } = useContext(AuthContext)
    const location = useLocation();
    const navigate = useNavigate();
    const [clientDetails, setClientDetails] = useState({});
    const [id, setId] = useState('');
    const [loading, setLoading] = useState(false);
    const [selectedModule, setSelectedModules] = useState([])
    const [moduleList, setModuleList] = useState([])

    const updateModules = async() => {
        setLoading(true);
        try {
            const {data} = await axios.post(`${AppConfig.baseUrl}/client/updateClientModule`, {id: clientDetails._id, modules : selectedModule},{
              headers: { token: localStorage.getItem("token") }
            });
            if(!data.error){
                toast.success('Modules added successfully');
                navigate(`/${localStorage.getItem('company')}/clients/modules`)
            }
            else{
                throw new Error(data.title);
            }
        } catch (error) {
            toast.error(error.message || 'Something went wrong');
        }
        setLoading(false);
    }

    const handleModuleChange = (e)=>{
        if(selectedModule.includes(e.target.name)){
            let data = selectedModule.filter(i => i != e.target.name)
            setSelectedModules(data)
        }else{
            setSelectedModules([...selectedModule,e.target.name])
        }
    }

    useEffect(() => {
        setId((location.search).replace('?', ''));
    }, [])

    useEffect(() => {
        if(id){
            getClientDetails(id);
        }
        getModules();
    },[id,userData])
    
    const getClientDetails = async (client_id) => {
        setLoading(true)
        try {
            const {data} = await axios.get(`${AppConfig.baseUrl}/customer/clientdetails?id=${client_id}`,{
            headers: { token: localStorage.getItem("token") }
            });
            if(!data.error){
            setClientDetails(data.clientDetails)
            }
            else{
                toast.error("You don't have access to view this client modules" || data.title);
                navigate(`/${localStorage.getItem('company')}/clients`)
            }
        } catch (error) {
            toast.error(error.message || 'Something went wrong');
        }
        setLoading(false)
    }

    const getModules = async() =>{
        try{
            const {data} = await axios.post(`${AppConfig.baseUrl}/module/getCustomerModules`,{},{
                headers: { token: localStorage.getItem("token") }
            })
            if(!data.error){
                setModuleList(data.data)
            }else{
                throw new Error(data.title);
            }
        }catch(error){
            toast.error(error.message || "something went wrong");

        }
    }

    useEffect(()=>{
        setSelectedModules(clientDetails && clientDetails.modules ? clientDetails.modules : [])
    },[clientDetails])

    return (
        <Layout>
            <div className="page-content">
                <Helmet>
                    <title>LeaseJava | Modules</title>
                </Helmet>
                <Container fluid>
                    <Breadcrumb title="Modules" breadcrumbItem="Modules"/>
                    <Card>
                        <CardTitle className="mt-4 ms-3">Update Modules</CardTitle><hr/>
                        <CardBody>

                    {
                        loading ?
                            <div style={{textAlign: 'center'}}><Spinner color="primary" className="txt-theme-color" /></div>
                        :
                        <Row>
                            { moduleList && moduleList.length > 0 ? moduleList.map(m => 
                            <Col lg={4} key={m._id}>
                                <Card outline color={`${selectedModule.includes(m.tag) ? 'theme-color' : 'secondary'}`} className="border">
                                    <CardHeader className="bg-transparent">
                                        <h5 className={`my-0 ${selectedModule.includes(m.tag) ? 'txt-theme-color' : 'text-secondary'}`}>
                                            <div className="d-flex">
                                                <div className="form-check form-switch mt-2">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input switch-icon-color"
                                                        id={m.tag}
                                                        name={m.tag}
                                                        checked = {selectedModule.includes(m.tag)}
                                                        onChange={handleModuleChange}
                                                    />
                                                </div>
                                                <div >
                                                    <Label for={m.tag} className="col-form-label">
                                                    {m.title}
                                                    </Label>
                                                </div>
                                            </div>
                                        </h5>
                                    </CardHeader>
                                    <CardBody>
                                        <CardText className={`${selectedModule.includes(m.tag) ? 'txt-theme-color' : 'text-secondary'}`}>
                                            {m.description}
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </Col>
                            )
                            :<div>{"You don't have access to any module."}</div>
                        }
                        </Row>
                    }
                        </CardBody>
                    <hr />
                    <Col>
                        <button type="button" onClick={updateModules} disabled={loading} className="btn btn-primary bg-theme-color btn-block mb-4 ms-3 mt-2" style={{float:'left'}}>
                            SUBMIT
                        </button>
                    </Col>
                    </Card>
                    
                </Container>
            </div>
        </Layout>
    )
}

export default Modules